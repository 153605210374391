/* eslint-disable @typescript-eslint/no-unused-vars */
import { bnHelper } from '@/helpers/bignumber-helper'
import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import { getDefaultProvider } from '@ethersproject/providers'
import {
  Fetcher as PKFetcher,
  Route as PKRouter,
  Token as PKToken,
  TokenAmount as PKTokenAmount,
  Trade as PKTrade,
  TradeType as PKTradeType,
  WETH as PKWETH,
} from '@pancakeswap-libs/sdk-v2'
import { PublicKey } from '@solana/web3.js'
import { WETH9 } from '@uniswap/sdk-core'
import Web3 from 'web3'
import { blockchainHandler } from '.'
import erc20Abi from './abis/erc20.abi.json'
import uniSwapV3Abi from './abis/uniswap-v3.quote.abi.json'
import { splPriceStore } from './spl-token-helper'

const BSC_BUSD_ADDRESS = Web3.utils.toChecksumAddress('0xe9e7cea3dedca5984780bafc599bd69add087d56')
const BSC_BUSDToken = new PKToken(56, BSC_BUSD_ADDRESS, 18)
const BSC_WEB3 = blockchainHandler.getWeb3(56) as any
const { rpc } = blockchainHandler.getChainConfig(56)
const BSC_PROVIDER = getDefaultProvider(rpc)

const ETH_USDT_ADDRESS = Web3.utils.toChecksumAddress('0xdac17f958d2ee523a2206206994597c13d831ec7')
const ETH_WEB3 = blockchainHandler.getWeb3(1) as any
const uniQuoteV3Contract = new ETH_WEB3.eth.Contract(uniSwapV3Abi, '0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6')

let ethPrice: FixedNumber | null = null
let bscPrice: FixedNumber | null = null
const cachedTokenPrices = {
  BNB: {},
  ETH: {},
}
interface IPriceHandler {
  baseCoin2Usd(): Promise<FixedNumber>
  baseCoin2Token(
    tokenAddress: string,
    decimals: number,
    tokenAmount: FixedNumber
  ): Promise<{ price: FixedNumber; priceImpact: FixedNumber }>
  token2Usd(tokenAddress: string, decimals: number, tokenAmount: FixedNumber): Promise<FixedNumber>
  baseCoinBalance(address: string): Promise<FixedNumber>
}

class BscPriceHandler implements IPriceHandler {
  BUSDContract = new BSC_WEB3.eth.Contract(erc20Abi, BSC_BUSD_ADDRESS)

  async baseCoin2Usd() {
    if (!bscPrice) {
      const pair = await PKFetcher.fetchPairData(BSC_BUSDToken, PKWETH[56], BSC_PROVIDER)
      const route = new PKRouter([pair], BSC_BUSDToken)
      const value = new PKTokenAmount(BSC_BUSDToken, Web3.utils.toWei(`1`))
      const trade = new PKTrade(route, value, PKTradeType.EXACT_INPUT)
      bscPrice = FixedNumber.from(trade.executionPrice.invert().toSignificant(6))
    }
    return bscPrice
  }

  async baseCoin2Token(
    tokenAddress: string,
    decimals: number,
    tokenAmount: FixedNumber
  ): Promise<{ price: FixedNumber; priceImpact: FixedNumber }> {
    tokenAddress = Web3.utils.toChecksumAddress(tokenAddress)
    if (!cachedTokenPrices.BNB[tokenAddress]) {
      try {
        const token = new PKToken(56, tokenAddress, decimals)
        const pair = await PKFetcher.fetchPairData(token, PKWETH[56], BSC_PROVIDER)
        const route = new PKRouter([pair], token)
        const amount = new PKTokenAmount(token, bnHelper.toDecimalString(tokenAmount, decimals))
        const trade = new PKTrade(route, amount, PKTradeType.EXACT_INPUT)

        cachedTokenPrices.BNB[tokenAddress] = {
          price: FixedNumber.from(trade.executionPrice.invert().toSignificant(12)),
          priceImpact: FixedNumber.from(trade.priceImpact.toSignificant(12)),
        }
      } catch (error) {
        return {
          price: FixedNumber.from('0'),
          priceImpact: FixedNumber.from('0'),
        }
      }
    }

    return cachedTokenPrices.BNB[tokenAddress]
  }
  async token2Usd(tokenAddress: string, decimals: number, tokenAmount = FixedNumber.from('1')): Promise<FixedNumber> {
    const eth2usd = await this.baseCoin2Usd()
    const eth2token = await this.baseCoin2Token(tokenAddress, decimals, tokenAmount)
    return eth2token.price.isZero() ? FixedNumber.from('0') : eth2usd.divUnsafe(eth2token.price)
  }

  async baseCoinBalance(address: string): Promise<FixedNumber> {
    return await BSC_WEB3.eth.getBalance(address).then((x) => bnHelper.fromDecimals(x))
  }
}

class EthPriceHandler implements IPriceHandler {
  async baseCoin2Usd() {
    if (!ethPrice) {
      const bnb2Usd: FixedNumber = await uniQuoteV3Contract.methods
        .quoteExactOutputSingle(ETH_USDT_ADDRESS, WETH9[1].address, 3000, Web3.utils.toWei('1'), '0')
        .call()
        .then((x) => FixedNumber.from(x))
      ethPrice = bnb2Usd.divUnsafe(FixedNumber.from(BigNumber.from(10).pow(6).toString()))
    }
    return ethPrice
    // const poolAddress = await uniFactoryV3Contract.methods.getPool(ETH_USDT_ADDRESS, WETH9[1].address, 3000).call()
    // const poolContract = new ETH_WEB3.eth.Contract(require('./abis/uniswap-v3.pool.abi.json'), poolAddress)
    // const poolBalance = await poolContract.methods.slot0().call()
    // const sprtPriceX96 = FixedNumber.from(poolBalance[0])
    // return sprtPriceX96
    //   .mulUnsafe(sprtPriceX96)
    //   .mulUnsafe(FixedNumber.from(`${10 ** 18}`))
    //   .divUnsafe(FixedNumber.from(BigNumber.from(2).pow(192).toString()))
    //   .divUnsafe(FixedNumber.from(`${10 ** 6}`))
  }
  async baseCoin2Token(
    tokenAddress: string,
    decimals: number,
    tokenAmount: FixedNumber
  ): Promise<{ price: FixedNumber; priceImpact: FixedNumber }> {
    tokenAddress = Web3.utils.toChecksumAddress(tokenAddress)
    if (!cachedTokenPrices.ETH[tokenAddress]) {
      const bnb2token: FixedNumber = await uniQuoteV3Contract.methods
        .quoteExactOutputSingle(tokenAddress, WETH9[1].address, 3000, Web3.utils.toWei('1'), '0')
        .call()
        .then((x) => FixedNumber.from(x))
      cachedTokenPrices.ETH[tokenAddress] = {
        price: bnb2token.divUnsafe(FixedNumber.from(BigNumber.from(10).pow(decimals).toString())),
        priceImpact: FixedNumber.from('0'),
      }
    }
    return cachedTokenPrices.ETH[tokenAddress]
  }

  async token2Usd(tokenAddress: string, decimals: number, tokenAmount: FixedNumber) {
    const eth2usd = await this.baseCoin2Usd()
    const eth2token = await this.baseCoin2Token(tokenAddress, decimals, tokenAmount)
    return eth2usd.divUnsafe(eth2token.price)
  }

  async baseCoinBalance(address: string): Promise<FixedNumber> {
    return await ETH_WEB3.eth.getBalance(address).then((x) => bnHelper.fromDecimals(x))
  }
}

class SolHandler implements IPriceHandler {
  baseCoin2Usd(): Promise<FixedNumber> {
    throw new Error('Method not implemented.')
  }
  baseCoin2Token(
    tokenAddress: string,
    decimals: number,
    tokenAmount: FixedNumber
  ): Promise<{ price: FixedNumber; priceImpact: FixedNumber }> {
    throw new Error('Method not implemented.')
  }
  async token2Usd(tokenAddress: string, decimals: number, tokenAmount: FixedNumber): Promise<FixedNumber> {
    const price = await splPriceStore.getRaydiumPriceByAddress(new PublicKey(tokenAddress))
    return FixedNumber.from(price.toString())
  }
  baseCoinBalance(address: string): Promise<FixedNumber> {
    throw new Error('Method not implemented.')
  }
}

const ethHandler = new EthPriceHandler()
const bscHandler = new BscPriceHandler()
const solHandler = new SolHandler()

export const priceHelper = {
  ofChain: (chainId: number | 'bsc' | 'eth') => {
    switch (chainId) {
      case 1:
      case 'eth':
        return ethHandler
      case 56:
      case 'bsc':
        return bscHandler
      case 101:
      case 'sol':
        return solHandler
      default:
        // suport eth/bsc mainnet only now
        return null
    }
  },
}
