import { blockchainHandler } from '@/blockchainHandlers'
import { IIdoContract, TierConfig, TokenVestingSchedule } from '@/blockchainHandlers/ido-contract-interface'
import { promiseHelper } from '@/helpers/promise-helper'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { last, random, round } from 'lodash-es'
import { action, autorun, computed, IReactionDisposer, observable, reaction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject, timer } from 'rxjs'
import { takeUntil, takeWhile } from 'rxjs/operators'
import { formatDuration, getPoolState, PoolState } from '../business/swap-contract.business'
import { priceHelper } from './../../../blockchainHandlers/price-helper'
import { bnHelper } from './../../../helpers/bignumber-helper'

export class PoolStore {
  @observable participants = 0
  @observable tokenDecimals = 18
  @observable purchasedTokens = FixedNumber.from(0)
  @observable totalTokens = FixedNumber.from(0)
  @observable poolState?: PoolState
  @observable pool: FixedPoolModel
  @observable hasWhitelist = true
  @observable tokensFund = FixedNumber.from('0')
  @observable tradeValue = FixedNumber.from('1')
  @observable sellerTax = FixedNumber.from('5') // default 5USD
  @observable userFee = FixedNumber.from('5') // default 5USD
  @observable marketPrice = FixedNumber.from('0') // default 5USD
  @observable isPausedContract = false
  @observable unsoldTokensReedemed = false
  @observable redeemConfigs: TokenVestingSchedule[] = []
  @observable tierConfigs: TierConfig[] = []

  contract?: IIdoContract

  private _diposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  constructor(pool: FixedPoolModel) {
    this.pool = pool
    const { participants, tokensAllocated } = this.pool.data || {}
    if (participants) this.participants = participants
    if (tokensAllocated) this.purchasedTokens = FixedNumber.from(tokensAllocated)
    this.totalTokens = FixedNumber.from(pool.totalRaise || '0')
    this.tradeValue = FixedNumber.from(`${pool.ratio || 1}`)
    this.contract = blockchainHandler.idoContractFactory(pool)
    this._diposers = [
      reaction(
        () => this.progress,
        () => this.updatePoolState(),
        { fireImmediately: true }
      ),
      autorun(() => {
        if (
          walletStore.walletConnected &&
          walletStore.chainType === this.pool.chain &&
          walletStore.chainId === this.pool.chainId
        ) {
          this.contract?.injectProvider()
        }
      }),
    ]
    this.updatePoolState()
    timer(1000, 1000)
      .pipe(
        takeUntil(this._unsubcrible),
        takeWhile(() => !this.poolState || !this.poolState.ended)
      )
      .subscribe(() => {
        this.updatePoolState()
      })
    timer(60000, 60000)
      .pipe(
        takeUntil(this._unsubcrible),
        takeWhile(() => !!this.pool?.address && !this.isFinalized)
      )
      .subscribe(async () => {
        await promiseHelper.delay(random(30) * 1000) // prevent cors
        this.fetchPoolInfo()
      })
    this.loadData()
  }

  @asyncAction *loadData() {
    const contract = this.contract
    try {
      if (contract) {
        for (let index = 0; index < 3; index++) {
          try {
            yield contract.init()
            if (this.marketPrice.isZero()) yield this.loadMarketPrice()
            break
          } catch (error) {
            console.log('error', error)
            if (index === 3) throw error
            else yield promiseHelper.delay(10000)
          }
        }
        this.syncState()
      }
    } catch (error) {
      console.error(this.pool.name, this.pool.tokenName, error)
    }
  }

  @asyncAction *fetchPoolInfo() {
    const contract = this.contract
    try {
      if (contract) {
        yield contract.fetchPoolInfo()
        this.syncState()
      }
    } catch (error) {
      console.error(this.pool.name, this.pool.tokenName, error)
    }
  }

  @asyncAction *loadMarketPrice() {
    if (this.tokenAddress) {
      try {
        yield promiseHelper.delay(1)
        const chainId = +(this.pool?.chainId || 0)
        const tokenDecimals = this.tokenDecimals || 18
        // mainnet only
        if (chainId === 1 || chainId === 56 || chainId === 101) {
          this.marketPrice = yield (priceHelper.ofChain(chainId) as any).token2Usd(
            this.tokenAddress,
            tokenDecimals,
            FixedNumber.from('1')
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  @action syncState() {
    const contract = this.contract
    if (contract) {
      const {
        hasWhitelisting,
        redeemConfigs,
        tierConfigs,
        tokensAllocated,
        tokensForSale,
        tokensFund,
        tradeValue,
        unsoldTokenReedemed,
        participants,
        paused,
        userFee,
        sellerTax,
        tokenDecimals,
      } = contract.poolInfo
      this.sellerTax = sellerTax
      this.userFee = userFee
      this.tradeValue = tradeValue
      this.participants = participants
      this.isPausedContract = paused
      this.tokensFund = tokensFund
      this.totalTokens = tokensForSale
      this.hasWhitelist = hasWhitelisting
      this.purchasedTokens = tokensAllocated
      this.tierConfigs = tierConfigs.map((x) => ({ ...x }))
      this.redeemConfigs = redeemConfigs.map((x) => ({ ...x }))
      this.unsoldTokensReedemed = unsoldTokenReedemed
      this.tokenDecimals = tokenDecimals
    }
  }

  @asyncAction *updatePoolState() {
    this.poolState = yield getPoolState(this)
  }

  @action.bound changeModel(model: FixedPoolModel) {
    this.pool = model
  }

  @computed get progress() {
    if (this.purchasedTokens.isZero() || this.totalTokens.isZero()) return 0
    const result = this.purchasedTokens.divUnsafe(this.totalTokens).mulUnsafe(FixedNumber.from(100))
    return result.toUnsafeFloat()
  }

  @computed get startIn() {
    if (this.poolState) {
      const { startDuration } = this.poolState
      return `in ${formatDuration(startDuration)}`
    }
    return ''
  }

  @computed get isFinalized() {
    return this.poolState?.ended
  }

  @computed get canRedeemTokens() {
    return this.isFinalized
  }

  @computed get vestingDuration() {
    const lastVesting = last(this.redeemConfigs)
    if (lastVesting) {
      const _duration = moment.duration(lastVesting.date.diff(moment(this.endDate)))
      return round(_duration.asMonths()) + ' month(s)'
    }
    return 'TBA'
  }
  @computed get vestingDurationInMonths() {
    const lastVesting = last(this.redeemConfigs)
    if (lastVesting) {
      const _duration = moment.duration(lastVesting.date.diff(moment(this.endDate)))
      return round(_duration.asMonths())
    }
    return 0
  }
  @computed get logoImage() {
    return this.pool?.logoUrl || this.pool?.file
  }
  @computed get minAllocationUsd() {
    return this.pool?.data?.minAllocationUsd
  }
  @computed get maxAllocationUsd() {
    return this.pool?.data?.maxAllocationUsd
  }
  @computed get totalRaiseUsd() {
    return this.pool?.data?.totalRaiseUsd
  }
  @computed get totalPurchasedInTradeToken() {
    return this.purchasedTokens.mulUnsafe(this.tradeValue)
  }
  @computed get shortDescription() {
    return this.pool?.data?.shortDescription
  }
  @computed get coverUrl() {
    return this.pool?.data?.coverUrl
  }
  @computed get medium() {
    return this.pool?.data?.medium
  }
  @computed get telegram() {
    return this.pool?.data?.telegram
  }
  @computed get announcementTelegram() {
    return this.pool?.data?.announcementTelegram
  }
  @computed get discord() {
    return this.pool?.data?.discord
  }
  @computed get twitterShareLink() {
    return this.pool?.data?.twitterShareLink
  }
  @computed get twitterShareLink2() {
    return this.pool?.data?.twitterShareLink2
  }
  @computed get twitter() {
    return this.pool?.data?.twitter
  }
  @computed get forceHide() {
    return this.pool?.data?.forceHide || false
  }
  @computed get forceFilledPool() {
    return this.pool?.data?.forceFilledPool || false
  }
  @computed get isNotDescriptionMarkdown() {
    return this.pool?.data?.isNotDescriptionMarkdown || false
  }
  @computed get isTBASale() {
    return this.pool?.data?.isTBASale || false
  }
  @computed get facebook() {
    return this.pool?.data?.facebook
  }
  @computed get reddit() {
    return this.pool?.data?.reddit
  }
  @computed get twitch() {
    return this.pool?.data?.twitch
  }
  @computed get youtube() {
    return this.pool?.data?.youtube
  }
  @computed get tiktok() {
    return this.pool?.data?.tiktok
  }
  @computed get instagram() {
    return this.pool?.data?.instagram
  }
  @computed get linkedIn() {
    return this.pool?.data?.linkedIn
  }
  @computed get blog() {
    return this.pool?.data?.blog
  }
  @computed get web() {
    return this.pool?.data?.web
  }
  @computed get whitelistUrl() {
    return this.pool?.data?.whitelistUrl
  }
  @computed get tradeToken() {
    return this.pool?.tradeToken
  }
  @computed get tradeByErc20() {
    return true
  }
  @computed get chainId() {
    return this.pool.chainId
  }
  @computed get chain() {
    return this.pool.chain
  }
  @computed get ended() {
    return !!this.poolState?.ended
  }
  @computed get userTax() {
    return this.pool.userTax || 0
  }
  @computed get tokenName() {
    return this.pool.tokenName
  }
  @computed get tokenAddress() {
    return this.pool.tokenAddress
  }
  @computed get accessType() {
    return this.pool.accessType
  }
  @computed get unicodeName() {
    return this.pool.unicodeName
  }
  @computed get projectName() {
    return this.pool.name
  }
  @computed get discount() {
    return this.pool?.data?.discount || 0
  }
  @computed get whitelistConfig() {
    return this.pool?.data?.whitelistConfig
  }
  @computed get roiLevel() {
    return this.pool?.data?.roiLevel
  }
  @computed get whitelistStartDate() {
    return this.whitelistConfig?.whitelistStartDate ? moment(this.whitelistConfig?.whitelistStartDate) : ''
  }
  @computed get whitelistEndDate() {
    return this.whitelistConfig?.whitelistEndDate ? moment(this.whitelistConfig?.whitelistEndDate) : ''
  }
  @computed get extendKycEndDate() {
    return this.whitelistConfig?.extendKycEndDate ? moment(this.whitelistConfig?.extendKycEndDate) : ''
  }
  @computed get publicWhitelistDate() {
    return this.whitelistConfig?.publicWhitelistDate ? moment(this.whitelistConfig?.publicWhitelistDate) : ''
  }
  @computed get startDate() {
    return this.pool.startDate || ''
  }
  @computed get endDate() {
    return this.pool.endDate
  }

  @computed get allowSwap() {
    const ended = this.poolState?.ended
    const started = this.poolState?.started
    // const isWhitelisted = this.isWhitelisted
    // const hasWhitelist = this.hasWhitelist
    return started && !ended // && (!hasWhitelist || isWhitelisted)
  }
  @computed get purchasedToUsd() {
    return this.purchasedTokens.mulUnsafe(this.tradeValue)
  }
  @computed get totaRaiseToUsd() {
    return this.totalTokens.mulUnsafe(this.tradeValue)
  }
  @computed get fundableToken() {
    return this.totalTokens.subUnsafe(this.tokensFund)
  }
  @computed get tierPools() {
    if (!this.poolState) return []
    const poolState = this.poolState
    return this.tierConfigs.map((tier) => {
      const name = this.getTierText(tier.id)
      const startDate = moment(this.startDate).add(moment.duration(tier.delayTime, 'seconds'))
      const currentTime = poolState.currentTime
      const isSaleStarted = currentTime.isAfter(startDate)
      const onSale = currentTime.isBetween(startDate, this.endDate)
      let countdownTargetDate, countdownText

      if (!isSaleStarted) {
        countdownTargetDate = startDate
        countdownText = 'Start in'
      } else if (onSale) {
        countdownTargetDate = this.endDate
        countdownText = 'End in'
      } else {
        countdownTargetDate = this.endDate
        countdownText = 'Ended'
      }
      const now = Math.trunc(currentTime.unix())
      const dateInMilliseconds = !countdownTargetDate ? 0 : Math.trunc(Date.parse(countdownTargetDate) / 1000)
      const countdownDays = Math.trunc((dateInMilliseconds - now) / 60 / 60 / 24)
      const countdownHours = countdownDays * 24 + (Math.trunc((dateInMilliseconds - now) / 60 / 60) % 24)
      const countdownMinutes = Math.trunc((dateInMilliseconds - now) / 60) % 60
      const countdownSeconds = (dateInMilliseconds - now) % 60

      const filled = bnHelper.lt(tier.tokensForSale.subUnsafe(tier.tokensAllocated), FixedNumber.from('1'))
      const progress = tier.tokensForSale.isZero()
        ? 100
        : tier.tokensAllocated.divUnsafe(tier.tokensForSale).mulUnsafe(FixedNumber.from(100)).toUnsafeFloat()
      return {
        ...tier,
        name,
        startDate,
        isSaleStarted,
        canSwap: startDate.isBefore(poolState.currentTime) && !poolState.ended,
        filled,
        progress,
        countdownDays,
        countdownHours,
        countdownMinutes,
        countdownSeconds,
        countdownText,
      }
    })
  }

  @computed get disableTransfer() {
    return this.pool?.data?.disableTransfer
  }

  private getTierText(tierId) {
    switch (tierId) {
      case 1:
        return 'MINI BEE'
      case 2:
        return 'WORKER BEE'
      case 3:
        return 'DRONE BEE'
      case 4:
        return 'QUEEN BEE'
      default:
        return 'Community'
    }
  }

  destroy() {
    this._diposers.forEach((d) => d())
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }
}
