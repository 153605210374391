import { round } from 'lodash-es'
import moment, { Duration, duration } from 'moment'
import { PoolStore } from '../stores/pool-store'

interface PoolState {
  filled: boolean
  started: boolean
  ended: boolean
  startDuration: Duration
  endDuration: Duration
  whitelistStartDuration: Duration
  whitelistEndDuration: Duration
  whitelistPublicDuration: Duration
  kycEndDuration: Duration
  countdownTargetDate: string
  countdownText: string
  countdownSeconds: number
  countdownMinutes: number
  countdownHours: number
  countdownDays: number
  isWhitelistStarted: boolean
  isWhitelistEnd: boolean
  isWhitelistPublic: boolean
  isKycEnd: boolean
  currentTime: moment.Moment
  isTBASale: boolean
  isTBACountDown: boolean
}

const formatDuration = (duration: moment.Duration) => {
  if (!duration) return ''
  let text = ''
  const makeNumber = (n) => round(Math.abs(n))
  const remainedHours = makeNumber(duration.asHours())
  const remainedSeconds = makeNumber(duration.asSeconds())
  if (remainedHours > 24) {
    text = `${makeNumber(duration.asDays())} day(s)`
  } else if (remainedHours > 1) {
    text = `${makeNumber(duration.asHours())} hour(s)`
  } else if (remainedSeconds > 60) {
    text = `${makeNumber(duration.asMinutes())} minute(s)`
  } else {
    text = `${makeNumber(remainedSeconds)} second(s)`
  }
  return text
}

const getPoolState = async (store: PoolStore): Promise<PoolState> => {
  const { pool, progress } = store
  const { startDate, endDate } = pool
  const isTBASale = pool?.data?.isTBASale || false
  const {
    whitelistStartDate,
    whitelistEndDate,
    publicWhitelistDate,
    kycEndDate,
    isTBAWhitelistStartDate,
    isTBAWhitelistEndDate,
    isTBAWhitelistPublicDate,
    isTBAKYCEndDate,
  } = pool?.data?.whitelistConfig || ({} as any)
  const currentTime = moment()
  const started = moment(startDate).isBefore(currentTime)
  const startDuration = duration(currentTime.diff(moment(startDate)))
  const ended: any = endDate && moment(endDate).isBefore(currentTime)
  const endDuration: any = endDate && duration(moment(endDate).diff(currentTime))
  const filled = progress > 99
  // get date for displaying count down whitelist
  const isWhitelistStarted = currentTime.isAfter(whitelistStartDate)
  const whitelistStartDuration = duration(currentTime.diff(whitelistStartDate))
  const whitelistEndDuration = duration(currentTime.diff(whitelistEndDate))
  const whitelistPublicDuration = duration(currentTime.diff(publicWhitelistDate))
  const kycEndDuration = duration(currentTime.diff(kycEndDate))
  const isWhitelistEnd = currentTime.isAfter(whitelistEndDate)
  const isWhitelistPublic = currentTime.isAfter(publicWhitelistDate)
  const isKycEnd = currentTime.isAfter(kycEndDate)
  let countdownTargetDate, countdownText, isTBACountDown
  if (!isWhitelistStarted) {
    countdownTargetDate = whitelistStartDate
    countdownText = 'Whitelist open in'
    if (isTBAWhitelistStartDate) {
      isTBACountDown = true
    }
  } else if (!isWhitelistEnd) {
    countdownTargetDate = whitelistEndDate
    countdownText = 'Whitelist close in'
    if (isTBAWhitelistEndDate) {
      isTBACountDown = true
    }
  } else if (!isWhitelistPublic) {
    countdownTargetDate = publicWhitelistDate
    countdownText = 'Whitelist results in'
    if (isTBAWhitelistPublicDate) {
      isTBACountDown = true
    }
  } else if (!isKycEnd) {
    countdownText = 'Final result announced in'
    countdownTargetDate = kycEndDate
    if (isTBAKYCEndDate) {
      isTBACountDown = true
    }
  } else if (!started) {
    countdownTargetDate = new Date(startDate || '').getTime()
    countdownText = 'Sale start in'
    if (isTBASale) {
      isTBACountDown = true
    }
  } else if (!ended) {
    countdownTargetDate = new Date(endDate || '').getTime()
    countdownText = 'Sale close in'
    if (isTBASale) {
      isTBACountDown = true
    }
  } else {
    countdownTargetDate = new Date(endDate || '').getTime()
    countdownText = 'Sale has ended'
  }
  const now = Math.trunc(new Date().getTime() / 1000)
  const dateInSeconds = Math.trunc(countdownTargetDate / 1000)
  const countdownDays = Math.trunc((dateInSeconds - now) / 60 / 60 / 24)
  const countdownHours = Math.trunc((dateInSeconds - now) / 60 / 60) % 24
  const countdownMinutes = Math.trunc((dateInSeconds - now) / 60) % 60
  const countdownSeconds = (dateInSeconds - now) % 60
  const result: PoolState = {
    started,
    startDuration,
    ended,
    endDuration,
    filled,
    countdownTargetDate,
    countdownText,
    countdownDays,
    countdownHours,
    countdownMinutes,
    countdownSeconds,
    isWhitelistStarted,
    isWhitelistEnd,
    isWhitelistPublic,
    isKycEnd,
    currentTime,
    isTBASale,
    isTBACountDown,
    whitelistStartDuration,
    whitelistEndDuration,
    whitelistPublicDuration,
    kycEndDuration,
  }
  return result
  // if (ended) {
  //   return { ...result, state: filled ? 'Filled' : 'Ended', warn: !filled }
  // } else if (started) {
  //   const text = `remain ${formatDuration(endDuration)}`
  //   return { ...result, state: filled ? 'Filled' : text }
  // } else {
  //   const text = `in ${formatDuration(startDuration)}`
  //   return { ...result, state: text }
  // }
}

export { formatDuration, getPoolState, PoolState }
